.PhoneInput {
  border-radius: 5px;
  border: 0.5px solid #592e6d;
  padding: 5px 13px;
}
.PhoneInput > input {
  border: none;
  padding: 0 13px;
  font-size: 14px;
  height: 56px;
  color: #592e6d;
}
.PhoneInput > input:focus {
  border: none;
  outline: none;
}
.PhoneInput > input::placeholder {
  color: #592e6d;
}
